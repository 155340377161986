import {
    ArcRotateCamera, AssetContainer,
    Color4,
    CubeTexture,
    Engine, Mesh,
    Scene,
    SceneLoader,
    SceneSerializer,
    TransformNode,
    Vector3
} from "@babylonjs/core";
import * as GUI from "@babylonjs/gui";
import skeletonItem from "element-ui/packages/skeleton-item";
import image from "element-ui/packages/image";

export class ModelShowimag {
    constructor() {
        this.canvas = document.getElementById("canvas3")
        this.canvasParent = document.getElementById("canvas3-parent")


        this.engine = new Engine(this.canvas)
        this.scene = new Scene(this.engine)
        this.scene.clearColor = new Color4(0, 0, 0, 0.3)
        this.camera = new ArcRotateCamera("camera", 0, 1.2, 10, Vector3.Zero(), this.scene)
        this.camera.attachControl(this.canvas)
        this.camera.lowerRadiusLimit = 5
        this.camera.upperRadiusLimit = 50
        this.meshes = []

        this.asset = new AssetContainer(this.scene)

        this.scene.createDefaultLight(false)

        this.scene.environmentTexture = new CubeTexture('environment.env', this.scene)
        this.scene.environmentIntensity = 4
        this.isShow = false
        this.advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI",true  ,this.scene);
        this.advancedTexture.idealHeight=900;
        this.hide()
        this.images=[]
        window.addEventListener("resize", (e) => {
            this.engine.resize()
        })
    }

    show(mesh: string) {
        this.canvasParent.style.display = "block"
        this.isShow = true
        // this.scene.debugLayer.show({})


        var image = new GUI.Image("but", 'imag/' + mesh + ".jpg");

        // let data = SceneSerializer.SerializeMesh(mesh, false, true)
        // let blob = new Blob([JSON.stringify(data)], {type: "octet/stream"});
        // this.url = window.URL.createObjectURL(blob)
        // console.log(blob)
        this.images = image;
        this.engine.resize()

        // SceneLoader.ImportMesh(null, '', 'list/' + mesh + '.glb', this.scene, (meshes) => {
        //      this.meshes = [...this.meshes, ...meshes]
        //       let vectors = image.getHierarchyBoundingVectors()
        //       let center = vectors.max.add(vectors.min).scale(0.5)
        //
        //
        //      this.camera.radius = vectors.max.subtract(vectors.min).length()
        //       this.camera.setTarget(center)
        // })

        // let mesh = scene.getMeshByName('Plane050')


        // image.width = "100px";
        // image.height = "100px";
        image.autoScale = true;
        image.cornerRadius = 20;
        image.color = "Orange";
        image.thickness = 4;
        let xx = image.scaleX;
        let yy = image.scaleY;
        image.scaleX=xx-0.8;
        image.scaleY=yy-0.8;



        image.onWheelObservable.add(eventData => {
            let Y = eventData.y

            if (image.scaleX >= 0) {

                image.scaleX = image.scaleX + Y * 0.001
                image.scaleY = image.scaleY + Y * 0.001
                if (image.scaleX<0||image.scaleY<0)
                {
                    image.scaleX = xx-0.9
                    image.scaleY = yy-0.9
                }
            } else {
                image.scaleX = 0
                image.scaleY = 0
            }


        })
       // image.onPointerClickObservable =function(){



        // image.onPointerMoveObservable.add(eventData => {
        //
        //     let x = eventData.x
        //     let y = eventData.y
        //     // image.sourceLeft = image.sourceLeft + x* 0.001;
        //     // image.sourceTop = image.sourceTop + y* 0.001;
        //     image.sourceLeft = -x;
        //     image.sourceTop = -y;
        //
        // })
    // }
        this.advancedTexture.addControl(image);




        // this.engine.hideLoadingUI()
        // this.engine.loadingScreen = null
        this.engine.runRenderLoop(this.tick)
    }

    hide() {

        this.canvasParent.style.display = "none"
        this.meshes.forEach((mesh) => {
            mesh.dispose()

        })
       if (this.images)
       {
           this.advancedTexture.removeControl(this.images);
       }

        this.isShow = false
        if (this.url) {
            window.URL.revokeObjectURL(this.url)
            this.url = null
        }
        // this.engine.stopRenderLoop()
    }

    tick = () => {
        this.scene.render()
    }
}

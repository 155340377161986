import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'


Vue.config.productionTip = false
Vue.prototype.appId = 'wx08eda8fb8dbfc311';
Vue.prototype.redirectUri = 'https://ky3d.com/addons/yb_guanwang/core/index.php/api/index/openid';




new Vue({
  render: h => h(App),
}).$mount('#app')

<template>
  <div id="app">
    
    <el-dialog
        title="操作提示"
        width="70%"
        :visible.sync="showHelp"
    >

      <slot></slot>
      <el-image src="help.png"></el-image>
    </el-dialog>
    <div id="canvas2-parent" style="position: fixed; width: 100%;height: 100%;">
      <el-button @click="closeModelShow" class="el-icon-close" style="position: absolute;top: 100px; right: 200px;"
                 circle></el-button>
      <canvas id="canvas2" translate="yes" style=" width: 100%;height: 100%;">
      </canvas>

    </div>
    <!-- <Topicon :isComment="isComment" @changeCommentStatus="changeCommentStatus" class="top-icon"/> -->
    <div id="right-menu">
      <ul>
        <li @click="switchFullScreen" class="menu-item">
          <span class="iconfont">&#xe649;</span>
        </li>
        <li v-bind:class="{music:musicEnable}" @click="toggleMusic();" class="menu-item">
          <span class="iconfont">&#xe644;</span>
        </li>
        <li @click="showHelp = true" class="menu-item" >
          <span class="iconfont">&#xe619;</span>
        </li>
        <li class="menu-item" :class="{praise:isPraise== 1? true:false}" @click="setPraise">
          <span class="iconfont">&#xe61c;</span>
        </li>
        <li class="menu-item" @click="isComment = !isComment"  :class="{commentShow:isComment?true:false}">
          <span class="iconfont">&#xe63a;</span>
        </li>
      </ul>
    </div>

    <div id="canvas3-parent" style="position: fixed; width: 100%;height: 100%;">
      <el-button @click="closeModelShow" class="el-icon-close" style="position: absolute;top: 100px; right: 200px;"
                 circle></el-button>
      <canvas id="canvas3" translate="yes" style=" width: 100%;height: 100%;">
      </canvas>
    </div>
    <Comment v-show="isComment" class="comment" :userData="userData" :commentChange="isComment"/>
    <canvas id="canvas" style="width: 100%;height: 100%;"></canvas>
    
  </div>
</template>
<script src="https://cdn.babylonjs.com/babylon.js"></script>
<script src="https://preview.babylonjs.com/loaders/babylonjs.loaders.min.js"></script>
<script>
import Comment from './components/Comment.vue'
import Topicon from './components/Topicon.vue'
import {Game} from "@/js/GameFxs";
import {  getOpenid } from "@/js/api.js";

export default {
  name: 'app',
  components: {
    Comment,
    Topicon
  },
  data() {
    return {
      userData:[],
      isComment:false,
      musicEnable: true,
      showHelp:false,
      isPraise: 2
    }
  },
  created(){
    document.title = '字画馆';
    var praise = localStorage.getItem('isPraise')
    if(praise == null){
      this.isPraise  = 2
    }else{
      this.isPraise = praise
    }
    // if(this.isWx()){
    //   this.getCode();
    // }
  },
  methods: {
    isWx(){
        var ua = navigator.userAgent.toLowerCase();  
        if(ua.match(/MicroMessenger/i)=="micromessenger") {  
            return true;  
        } else {  
            return false;  
        }  
      },
    setPraise(){
      var praise = localStorage.getItem('isPraise')
     
      if(praise == null){
        localStorage.setItem('isPraise',1)
        this.isPraise = 1
      }else{
        if(praise == 1){
        localStorage.setItem('isPraise',2)
        this.isPraise = 2
        }else{
          localStorage.setItem('isPraise',1)
        this.isPraise = 1
        }
      }
      
      
    },
    changeCommentStatus(status){
      this.isComment = status
    },
    switchFullScreen() {
      if (document.fullscreenElement) {
        document.exitFullscreen()
      }
      this.$root.$el.requestFullscreen({})
    },
    toggleMusic() {
      this.musicEnable = this.game.switchMusic()
    },
    closeModelShow() {
      window.game.modelShow.hide()
      window.game.modelShowimag.hide()
    },
    getQueryString(name) {
        var search = window.document.location.href;
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]); return null;
    },
    getCode() {
        const code = this.getQueryString('code'); // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
        if (typeof code == 'undefined' || code == null) {
          window.location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          this.appId +
          "&redirect_uri=" +
          encodeURIComponent(this.redirectUri) +
          "&response_type=code&scope=snsapi_userinfo&state=silent#wechat_redirect";// 非静默授权
        }else{
          getOpenid({"code":code}).then( res => {
            if(res.data.error == 0){
              console.log(res.data.data)
                this.userData = res.data.data
                window.game = new Game(document.getElementById("canvas"))
            }
          })
      }
    },
  },
  mounted() {
    window.game = new Game(document.getElementById("canvas"))
  },

}
</script>

<style>
* {
  padding: 0;
  margin: 0;
}

html, body, #app {
  width: 100%;
  height: 100%;
}
.music {
  display: block;
  animation: music 2s linear infinite;
}

@keyframes music {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@font-face {
  font-family: 'iconfont';  /* Project id 3013934 */
  src: url('//at.alicdn.com/t/font_3013934_v73wlc8zol.woff2?t=1641885538760') format('woff2'),
       url('//at.alicdn.com/t/font_3013934_v73wlc8zol.woff?t=1641885538760') format('woff'),
       url('//at.alicdn.com/t/font_3013934_v73wlc8zol.ttf?t=1641885538760') format('truetype');
}
.iconfont {
  font-family: "iconfont", serif !important;
  font-size: 18px;
  padding: 8px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #F8F8FF;
}


.menu-item {
  padding: 5px;
  font-size: 20px;
  color: white;
  cursor: pointer;
  margin-bottom: 20px;
}
.menu-item.praise {
  color: #1989FA;
}
.menu-item.commentShow{
   color: #1989FA; 
}
li {
  list-style-type: none;
}
#right-menu {
  position: fixed;
  display: -webkit-flex; /* Safari */
  width: 30px;
  height: 200px;
  /*z-index: 1000;*/
  top: 32px;
  right: 24px;
}
.comment{
  position: fixed;
  bottom: 0;
  right:  0;
  width:60%;
  max-width: 600px;
}
.top-icon{
  position: fixed;
  top: 0px;
  right:  0px; 
}
</style>

import {
    ActionManager,
    Color3, CubeTexture,
    Engine,
    ExecuteCodeAction,
    Mesh,
    MeshBuilder,
    PBRMaterial,
    Scene,
    SceneLoader, StandardMaterial, Texture,
    Vector3
} from "@babylonjs/core";
import "@babylonjs/inspector"
import {Player} from "@/js/player";
import {ModelShow} from "@/js/modelShow";
import {ModelShowimag} from "@/js/modelShowimag";

import '@babylonjs/inspector'

import("@babylonjs/loaders")

export class Game {

    constructor(canvas: HTMLCanvasElement) {
        this.engine = new Engine(canvas, true)
        this.canvas = canvas
        this.scene = new Scene(this.engine)
        // this.scene.environmentTexture = new CubeTexture('/environment.env', this.scene)
        // this.scene.environmentIntensity = 2
        // this.skybox = this.scene.createDefaultSkybox(new CubeTexture('/environment.env', this.scene), true, undefined, 0.2)
        // this.skybox.applyFog = false

        this.scene.createDefaultLight()
        this.scene.createDefaultEnvironment({createSkybox: false})

        this.scene.fogMode = 3
        this.scene.fogDensity = 0.001
        this.scene.fogStart = 0
        this.scene.fogEnd = 600
        this.scene.fogColor = Color3.FromHexString("#414424")
        //
        this.scene.environmentIntensity = 2

        this.scene.imageProcessingConfiguration.vignetteEnabled = true
        this.scene.imageProcessingConfiguration.vignetteWeight = 1.5
        this.scene.imageProcessingConfiguration.vignetteStretch = 1
        this.scene.imageProcessingConfiguration.vignetteCameraFov = 0.6

        // this.scene.imageProcessingConfiguration.exposure = 4
        //
        // this.pipeLine = new DefaultRenderingPipeline("defaultPipe", true, this.scene)
        // this.pipeLine.bloomEnabled = true
        // this.pipeLine.imageProcessing.toneMappingType = 0
        // this.pipeLine.imageProcessing.toneMappingEnabled = true

        const ground = MeshBuilder.CreateGround("", {width: 1000, height: 1000})
        ground.checkCollisions = true
        ground.position.y = -0.1
        ground.visibility = 0


        this.modelShow = new ModelShow()
        this.modelShowimag = new ModelShowimag()

        this.camera = new Player('player1', new Vector3(0, 4, 0), this.scene)
        this.camera.maxZ = 100000
        this.camera.attachControl(this.canvas)


        window.addEventListener('keydown', e => {
                if (e.key === "i") {
                    if (this.scene.debugLayer.isVisible()) {
                        this.scene.debugLayer.hide()
                    } else {
                        this.scene.debugLayer.show({handleResize: true, embedMode: true})
                    }
                }
                if (e.key === "t") {
                    if (this.modelShow.isShow) {
                        this.modelShow.hide()
                    } else {
                        this.modelShow.show('a1')
                    }
                }
            }
        )


        SceneLoader.Append('/', 'scene1.glb', this.scene, scene => {
            // this.initAlpha(this.scene)
            this.initCollision(this.scene)  //这两个函数报错了， 导致后面都不执行
            this.initLightMap(this.scene)



            this.scene.meshes.filter(mesh => mesh.name.indexOf('立方体_collision') !== -1).forEach(mesh => mesh.checkCollisions = true)

            // this.scene.meshes.forEach(mesh => {
            //     console.log(mesh)
            //     mesh.checkCollisions = true
            // })
            // 全部开启碰撞
            // const canPickmesh = [
            //     'Plane050',
            //     "b1",
            // ]
            //
            // this.scene.getMeshByName('xxx')
            // this.scene.onPointerPick = (e,pickInfo)=>{
            //     let mesh = canPickmesh.find(item => item === pickInfo.pickedMesh.name)
            //     if (mesh){
            //         // do
            //
            //     }
            // }//第一种方法

            // let mesh = this.scene.getMeshByName('123')
            // mesh.actionManager = new ActionManager(this.scene)
            // mesh.actionManager.registerAction(
            //     new ExecuteCodeAction(
            //         {
            //             trigger: ActionManager.OnPickTrigger
            //         },
            //         (evt)=>{
            //             //mesh.material  = xxx
            //         }
            //     )
            // )


            // fxs
            // this.scene.clearColor = new Color3(0,1,1);

            this.scene.ambientColor = new Color3.White();
            // let videoMat: PBRMaterial = this.scene.getMeshByName('b1').material
            // let videoTexture = new VideoTexture("b1-video", 'coub.mp4', this.scene, false, true)
            // videoTexture.video.muted = true
            // videoTexture.video.play()
            // videoMat.emissiveTexture = videoTexture
            // videoTexture.video.muted = true;

            // let sphere = MeshBuilder.CreateSphere("sphere", {}, this.scene)
            // NodeMaterial.ParseFromSnippetAsync("2F999G", this.scene).then(nodeMaterial => {
            //     sphere.material = nodeMaterial;
            //     nodeMaterial.edit({})
            //     console.log(nodeMaterial)
            // });

            // image.linkWithMesh(mesh);


            // // GUI
            // var advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI");
            //
            // var rect1 = new GUI.Rectangle();
            // rect1.width = 0.2;
            // rect1.height = "40px";
            // rect1.cornerRadius = 20;
            // rect1.color = "Orange";
            // rect1.thickness = 4;
            // rect1.background = "green";
            // advancedTexture.addControl(rect1);
            // rect1.isPointerBlocker = true;
            //
            //
            // rect1.onPointerClickObservable.add(e=>{
            //
            //
            //     alert('asdasd')
            // })

            // var label = new GUI.TextBlock();
            // label.text = mesh.name;
            // rect1.addControl(label);
            //
            // rect1.linkWithMesh(mesh);
            // rect1.linkOffsetY = -50;


            let canClickMeshesimag = [
                'Plane050',
            ]
            canClickMeshesimag.forEach(name => {
                let mesh = scene.getMeshByName(name)
                if (mesh) {

                    mesh.actionManager = new ActionManager(this.scene)
                    mesh.actionManager.registerAction(
                        new ExecuteCodeAction({trigger: ActionManager.OnPickTrigger},
                            (e) => {

                                this.modelShow.show(name)

                            }))
                }
            })


            //m模型jiaohu
            let canClickMeshes = [
                'Plane002',
                'Plane001',
                'Plane003',
                'Plane004',
                'Plane005',
                'Plane006',
                'Plane010',
                'Plane011',
                'Plane014',
                'Plane014.001',
                'Plane016',
                'Plane017',
                'Plane018',
                'Plane019',
                'Plane020',
                'Plane021',
                'Plane022',
                'Plane023',
                'Plane024',
                'Plane054',
                'Plane055',
                'Plane056',
                'Plane058',
                'Plane060',
                'Plane061',
                'Plane062',
                'Plane063',
                'Plane064',
                'Plane065',
                'Plane066',
                'Plane068',
                'Plane069',
                'Plane076', 'Plane077', 'Plane078', 'Plane081', 'Plane083', 'Plane091', 'Plane092', 'Plane093', 'Plane094', 'Plane096', 'Plane097', 'Plane098', 'Plane099', 'Plane100', 'Plane101',
                'Plane102', 'Plane103', 'Plane104', 'Plane105', 'Plane106', 'Plane107', 'Plane108', 'Plane110'
            ]


            canClickMeshes.forEach(name => {
                let mesh = scene.getMeshByName(name)
                if (mesh) {

                    mesh.actionManager = new ActionManager(this.scene)
                    mesh.actionManager.registerAction(
                        new ExecuteCodeAction({trigger: ActionManager.OnPickTrigger},
                            (e) => {

                                // this.modelShow.show(name)
                                this.modelShowimag.show(name)


                            }))
                }
            })


            // MeshBuilder.CreatePlane('plane', {size: 4}, this.scene).position = new Vector3(0, 4, 10)
            // new Ppt(this.scene.getMeshByName('b2'))
        })

        let skyBox =  MeshBuilder.CreateSphere('sky',{},this.scene)
        skyBox.checkCollisions =false
        skyBox.scaling.x = -1
        skyBox.scaling.scaleInPlace(1000)
        skyBox.applyFog=false
        let skyMat = new StandardMaterial('skyMat',this.scene)
        let skyTexture = new Texture('qwe.jpg',this.scene,false,false)
        skyMat.backFaceCulling = false
        skyMat.diffuseTexture = skyTexture
        skyMat.emissiveColor = Color3.White()
        skyBox.material = skyMat
        skyBox.isPickable = false

        this.engine.runRenderLoop(() => {
            this.scene.render()
        })

        window.addEventListener("resize", e => {
                this.engine.resize()
            }
        )
    }
    switchMusic() {
        if (this.music.isPlaying) {
            this.music.pause()
            return false
        } else {
            this.music.play()
            this.music.setVolume(1)
            return true
        }
    }

    initLightMap(scene) {
        scene.meshes.forEach(mesh => {
            if (mesh.material instanceof PBRMaterial) {
                if (mesh.material.emissiveTexture) {
                    // mesh.material.lightmapTexture = mesh.material.emissiveTexture
                    // mesh.material.useLightmapAsShadowmap = true
                    // mesh.material.emissiveTexture = null
                    // mesh.material.emissiveColor = Color3.Black()
                    // mesh.material.ambientColor = Color3.White()

                    //光照贴图实现2
                    mesh.material.ambientTexture = mesh.material.emissiveTexture
                    mesh.material.ambientTexture.isRGBD = true
                    mesh.material.emissiveColor = Color3.Black()
                    mesh.material.ambientColor = Color3.White()

                }
            }
        })
    }

    initCollision(scene: Scene) {
        this.scene.meshes.filter(mesh=>mesh.name.indexOf('立方体_collison') >=0 ).forEach(mesh => {
            console.log(mesh.name)
            mesh.checkCollisions = true
            mesh.isPickable = false
            if (mesh instanceof Mesh) {
                mesh.visibility = 0
            }
        })

        // this.scene.getNodeByName("Collison").getChildMeshes().forEach(mesh => {
        //     mesh.checkCollisions = true
        //     if (mesh instanceof Mesh) {
        //         mesh.visibility = 0
        //     }
        // })

    }

    initAlpha(scene) {
        scene.materials.forEach(mat => {
            if (mat instanceof PBRMaterial) {
                if (mat.alpha < 0.9) {
                    // mat.metallic = 0
                    // mat.roughness = 0
                    // mat.subSurface.isRefractionEnabled = true
                    // mat.subSurface.refractionIntensity = 0.8
                    mat.transparencyMode = 2
                }
                if (mat.albedoTexture) {
                    mat.useAlphaFromAlbedoTexture = true
                    mat.albedoTexture.hasAlpha = true
                    mat.transparencyMode = 1
                }
            }
        })
    }
}

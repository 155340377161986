import {
    ArcRotateCamera, AssetContainer,
    Color4,
    CubeTexture,
    Engine, Mesh,
    Scene,
    SceneLoader,
    SceneSerializer,
    TransformNode,
    Vector3
} from "@babylonjs/core";
import * as GUI from "@babylonjs/gui";

export class ModelShow {
    constructor() {
        this.canvas = document.getElementById("canvas2")
        this.canvasParent = document.getElementById("canvas2-parent")


        this.engine = new Engine(this.canvas)
        this.scene = new Scene(this.engine)
        this.scene.clearColor = new Color4(0, 0, 0, 0.3)
        this.camera = new ArcRotateCamera("camera", 0, 1.2, 10, Vector3.Zero(), this.scene)
        this.camera.attachControl(this.canvas)
        this.camera.lowerRadiusLimit = 5
        this.camera.upperRadiusLimit = 50
        this.meshes = []

        this.asset = new AssetContainer(this.scene)

        this.scene.createDefaultLight(false)

        this.scene.environmentTexture = new CubeTexture('environment.env', this.scene)
        this.scene.environmentIntensity = 4
        this.isShow = false

        this.hide()
        window.addEventListener("resize", (e) => {
            this.engine.resize()
        })
    }

    show(mesh: string) {
        this.canvasParent.style.display = "block"
        this.isShow = true
        // this.scene.debugLayer.show({})



        // let data = SceneSerializer.SerializeMesh(mesh, false, true)
        // let blob = new Blob([JSON.stringify(data)], {type: "octet/stream"});
        // this.url = window.URL.createObjectURL(blob)
        // console.log(blob)

        this.engine.resize()

        SceneLoader.ImportMesh(null, '', 'list/' + mesh + '.glb', this.scene, (meshes) => {
            this.meshes = [...this.meshes, ...meshes]
            let vectors = meshes[0].getHierarchyBoundingVectors()
            let center = vectors.max.add(vectors.min).scale(0.5)


            this.camera.radius = vectors.max.subtract(vectors.min).length()
            this.camera.setTarget(center)
        })
        // this.engine.hideLoadingUI()
        // this.engine.loadingScreen = null
        this.engine.runRenderLoop(this.tick)
    }

    hide() {

        this.canvasParent.style.display = "none"
        this.meshes.forEach((mesh) => {
            mesh.dispose()
        })
        this.isShow = false
        if (this.url) {
            window.URL.revokeObjectURL(this.url)
            this.url = null
        }
        // this.engine.stopRenderLoop()
    }

    tick = () => {
        this.scene.render()
    }
}

<template>
  <div class="top-icon-list">
      <div>
          <div>
              <img @click="changeComment" src="/icon/comment.png">
          </div>
      </div>

  </div>
</template>

<script>

export default {
  name: 'Topicon',
  components: {

  },
  props: {
    isComment: Boolean
  },
  data() {
    return {
        
    }
  },
  created(){
    
  },
  mounted () {
   
  },
  methods: {
      changeComment(){
        console.log(this.isComment)
        var nowComment = !this.isComment
        this.$emit('changeCommentStatus',nowComment)   
      }
  },
  computed:{

  }
}
</script>

<style scoped>
    .top-icon-list{
        width: 60px;
    }
    .top-icon-list > div{
        width: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .top-icon-list > div > div{
        margin-top: 10px;
    }
    .top-icon-list > div > div > img{
        width: 30px;
        cursor:pointer;
    }
</style>
